import React from "react";
import './style.module.css';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Grid, Link, Button } from "@material-ui/core";
import TextEditor from '../Editor';
import { IntersectionObserver as InView } from "../../components/IntersectionObserver";
import { showEditIconBlue } from "../../server/constants";
import pin1 from '../../public/AgentImages/pin_1.png';
import pin2 from '../../public/AgentImages/pin_2.png';
import pin3 from '../../public/AgentImages/pin_3.png';
import pin4 from '../../public/AgentImages/pin_4.png';
import pin5 from '../../public/AgentImages/pin_5.png';
import pin6 from '../../public/AgentImages/pin_6.png';

const useStyles = makeStyles(() =>
  createStyles({
    overLay: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: '100%',
      width: '100%',
      padding: "100px 15px"
    }
  }),
);
type Props = {
  findAgentTitle: any,
  findAgentSubTitle: any,
  findAgentByZipTitle: any
}

function FindAgent(props: Props) {
  const classes = useStyles();
  const {findAgentTitle, findAgentSubTitle} = props;
  const [inView, setInView] = React.useState(false);
  return (
    <InView onChange={setInView}>
      <div className="sectionFindAgent">
        <div className={classes.overLay}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} md={6} className="textCenter">

              <TextEditor
                className="textWhite "
                align="center"
                sectionId={"findAgentTitle"}
                content={findAgentTitle}
                upperDivClass={showEditIconBlue}/>
              <TextEditor
                className="textWhite"
                align="center"
                sectionId={"findAgentSubTitle"}
                content={findAgentSubTitle}
                upperDivClass={showEditIconBlue}
              />
              <Link href={"/agent-locator"} style={{textDecoration: 'none', display: 'inline-block', height: 100}}>
                <Button
                  className="mt30 customBtn glow"
                  type="submit"
                  variant="contained"
                >
                  Locate Agent Now
                </Button>
              </Link>
            </Grid>
          </Grid>
          {
            inView &&
              <div className="agentsImages">
                <span><img className="pinOne" src={pin1} alt={'agent1'}/> </span>
                <span><img className="pinTwo" src={pin2} alt={'agent2'}/> </span>
                <span><img className="pinThree" src={pin3} alt={'agent3'}/> </span>
                <span><img className="pinFour" src={pin4} alt={'agent4'}/> </span>
                <span><img className="pinFive" src={pin5} alt={'agent5'}/> </span>
                <span><img className="pinSix" src={pin6} alt={'agent6'}/> </span>
              </div>
          }
        </div>

      </div>
    </InView>

  );
}

export default FindAgent;
